import React from "react"

import Layout from "../../components/litjen-layout"
import SEO from "../../components/litjen-seo"


const LitjenLuke = () => (
  <Layout>
    <SEO title="Luke 22" />
    <div>
      <h1>Luke 22</h1>
      <p>God formiddag mamma litj!</p>
      <p>Nå nærmer feiringen av Jesu fødsel seg med stormskritt! Jeg er så spent at jeg tro jeg blir gæææærn!</p>
      <p>Hva heter den sangen igjen? "Et barn er født i..."</p>
      <p>Hmmm...</p>
      <p>Et barn er født i....</p>
      <p style={{marginTop: '100px'}}>Et barn er født i.... B..</p>
      <p style={{marginTop: '100px'}}>...</p>
      <p style={{marginTop: '100px'}}>...</p>
      <p style={{marginTop: '200px'}}>...</p>
      <p style={{marginTop: '300px'}}>...</p>
      <p style={{marginTop: '100px'}}>Et barn er født i.... Bbbb..</p>
      <p style={{marginTop: '400px'}}>...</p>
      <p style={{marginTop: '1400px'}}>...</p>
      <p style={{marginTop: '1000px'}}>...</p>
      <p style={{marginTop: '700px'}}>Et</p>
      <p style={{marginTop: '700px'}}>barn</p>
      <p style={{marginTop: '700px'}}>er</p>
      <p style={{marginTop: '700px'}}>født</p>
      <p style={{marginTop: '700px'}}>i</p>
      <p style={{marginTop: '1700px'}}>...</p>
      <p style={{marginTop: '1700px', fontSize: '3rem'}}>Bærums by!</p>
      <p style={{marginTop: '100px'}}>Og det var meg! Tihiihihihiihihi!</p>
      <p style={{marginTop: '100px'}}>Hilsen Litjen</p>
    </div>
  </Layout>
)

export default LitjenLuke